<template>
    <div class="vx-col w-5/6 ml-auto mr-auto">
        <span>
            <h4 class="mb-5">Detail Discount</h4>
        </span>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <vs-input class="w-full" label="Code" name="Code" v-model="data.code" readonly/>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <vs-input class="w-full" label="Name" name="Name" v-model="data.name" readonly/>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <vs-textarea class="w-full" label="Notes" name="Notes" v-model="data.notes" readonly/>
            </div>
        </div>
        <div class="vx-row mb-6" style="display: flex">
            <div class="vx-col w-1/2" style="display: flex">
                <label class="vs-input--label">Model</label>
                <ul class="ml-6">
                    <li>
                        <vs-radio v-if="data.model == 'Final'" v-model="data.model" vs-value="Final">{{data.model}}</vs-radio>
                        <vs-radio v-else v-model="data.model" vs-value="Step Ladder">{{data.model}}</vs-radio>
                    </li>
                </ul>
            </div>
            <div class="vx-col w-1/2" style="display: flex">
                <label class="vs-input--label">Unit</label>
                <ul class="ml-6">
                    <li>
                        <vs-radio v-if="data.unit == 'Total'" v-model="data.unit" vs-value="Total">{{data.unit}}</vs-radio>
                        <vs-radio v-else v-model="data.unit" vs-value="Line">{{data.unit}}</vs-radio>
                    </li>
                </ul>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <vs-input class="w-full" label="Valid From" name="Valid From" v-model="data.valid_from" readonly/>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <vs-input class="w-full" label="Valid To" name="Valid To" v-model="data.valid_to" readonly/>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <vs-input class="w-full" label="Currency Code" name="Currency Code" v-model="data.currency_code" readonly/>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <vs-input class="w-full" label="Discont Cap" name="Discount Cap" v-model="data.discount_cap" readonly/>
            </div>
        </div>
        <vs-table 
            stripe
            border
            description 
            :sst="true"
            :data="steps"
            class="vx-row mb-6 ml-1 mr-1"
        >
            <template slot="thead">
                <vs-th>Step</vs-th>
                <vs-th>From</vs-th>
                <vs-th>To</vs-th>
                <vs-th>Discount</vs-th>
                <vs-th>Cap</vs-th>
            </template>
            <template :data="steps">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in steps">
                    <vs-td>{{tr.step}}</vs-td>
                    <vs-td>
                        <span>{{tr.from}}</span>
                    </vs-td>
                    <vs-td>
                        <span>{{tr.to}}</span>
                    </vs-td>
                    <vs-td>
                        <span>{{tr.discount}}% ({{tr.discount_amount}})</span>
                    </vs-td>
                    <vs-td>
                        <span>{{tr.discount_line_cap}}</span>
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
    </div>
</template>
<script>

export default {
    components: {},
    props: {
        discountId: {
            type: Number
        }
    },
    created() {},
    data() {
        return this.initialState();
    },
    methods: {
        initialState() {
            return {
                steps: [],
                data: {
                    code: "",
                    name: "",
                    notes: "",
                    model: "",
                    unit: "",
                    valid_from: "",
                    valid_to: "",
                    discount_cap: "",
                    currency_code: ""
                }
            };
        },
        getData() {
        this.$vs.loading();
            if (this.discountId) {
                this.$http
                .get("/api/v1/master/discount/" + this.discountId)
                .then(resp => {
                    this.$vs.loading.close();
                    var _this = this
                    if (resp.code == 200) {
                        this.data.code = resp.data.code;
                        this.data.name = resp.data.name;
                        this.data.notes = resp.data.notes;
                        this.data.model = resp.data.model;
                        this.data.unit = resp.data.unit;
                        this.data.currency_code = resp.data.currency_code;
                        this.data.valid_from = resp.data.valid_from.substr(0, 10);
                        this.data.valid_to = resp.data.valid_to.substr(0, 10);
                        this.data.discount_cap = resp.data.discount_cap.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        this.steps = resp.data.steps;

                        resp.data.steps.forEach(function(element, index){
                            _this.steps[index]["from"] = element.from.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            _this.steps[index]["to"] = element.to.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            _this.steps[index]["discount_amount"] = element.discount_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            _this.steps[index]["discount_line_cap"] = element.discount_line_cap.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        })
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle"
                        });
                    }
                })
                .catch(error => {
                    this.$vs.loading.close();
                    console.log(error);
                });
            }
        },
        
    },
    mounted() {
        if (this.discountId) {
            this.getData();
        }
    },
    computed: {},
    watch: {
        discountId() {
            Object.assign(this.$data, this.initialState());
            this.getData();
        }
    }
};
</script>