<template>
    <div class="vx-col w-5/6 ml-auto mr-auto">
        <span>
            <h4 class="mb-5" v-if="ruleId!=null">Edit List Price Rule</h4>
            <h4 class="mb-5" v-else>Create List Price Rule</h4>
        </span>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <vs-input class="w-full" label="Code" name="Code" v-model="data.code" readonly/>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <vs-input v-validate="'required|numeric'" class="w-full" label="Priority" name="priority" v-model.number="data.priority"/>
                <span
                    class="text-danger text-sm"
                    v-show="errors.has('priority')"
                    >{{ errors.first('priority') }}
                </span>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <vs-input v-validate="'required'" class="w-full" label="Name" name="name" v-model="data.name" />
                <span
                    class="text-danger text-sm"
                    v-show="errors.has('name')"
                    >{{ errors.first('name') }}
                </span>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <label class="vs-input--label">List Price</label>
                <v-select v-validate="'required'" name="list price" v-model="data.price_name" label="name" placeholder="Select price" :options="prices" @input="setSelectedPrice">
                    <template slot="option" slot-scope="option">
                        <div style="align-items: center;"><span>{{option.code}} - {{option.name}}</span></div>
                    </template>
                </v-select>
                <span
                    class="text-danger text-sm"
                    v-show="errors.has('list price')"
                    >{{ errors.first('list price') }}
                </span>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <label class="vs-input--label">Discount</label>
                <v-select v-validate="'required'" name="discount" v-model="data.discount_name" label="name" placeholder="Select discount" :options="discounts" @input="setSelectedDiscount">
                    <template slot="option" slot-scope="option">
                        <div style="align-items: center;"><span>{{option.code}} - {{option.name}}</span></div>
                    </template>
                </v-select>
                <span
                    class="text-danger text-sm"
                    v-show="errors.has('discount')"
                    >{{ errors.first('discount') }}
                </span>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <label class="vs-input--label">Valid From</label>
                <datepicker :disabledDates="disabledDatesFrom" v-validate="'required'" name="valid from" :inline="false" v-model="data.valid_from" placeholder="Select Date"></datepicker>
                <span
                    class="text-danger text-sm"
                    v-show="errors.has('valid from')"
                    >{{ errors.first('valid from') }}
                </span>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <label class="vs-input--label">Valid To</label>
                <datepicker :disabledDates="disabledDatesTo" v-validate="'required'" name="valid to" :inline="false" v-model="data.valid_to" placeholder="Select Date"></datepicker>
                <span
                    class="text-danger text-sm"
                    v-show="errors.has('valid to')"
                    >{{ errors.first('valid to') }}
                </span>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <label class="vs-input--label">Active</label>
                <vs-switch v-model="data.is_active"/>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <label class="vs-input--label">Master</label>
                <vs-switch v-model="data.is_master"/>
            </div>
        </div>
        <div>
            <vs-divider color="primary"> Applicable to </vs-divider>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <label class="vs-input--label">Sales Channel</label>
                <v-select v-validate="'required'" name="sales channel" v-model="dataGet.sales_channel" label="name" placeholder="Select sales channel" :options="salesChannel" @input="setSelectedSalesChannel">
                    <template slot="option" slot-scope="option">
                        <div style="align-items: center;"><span>{{option.code}} {{option.name}}</span></div>
                    </template>
                </v-select>
                <span
                    class="text-danger text-sm"
                    v-show="errors.has('sales channel')"
                    >{{ errors.first('sales channel') }}
                </span>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <label class="vs-input--label">Sales Area</label>
                <v-select v-validate="'required'" name="sales area" v-model="dataGet.sales_area" label="name" placeholder="Select sales area" :options="salesArea" @input="setSelectedSalesArea">
                    <template slot="option" slot-scope="option">
                        <div style="align-items: center;"><span>{{option.code}} {{option.name}}</span></div>
                    </template>
                </v-select>
                <span
                    class="text-danger text-sm"
                    v-show="errors.has('sales area')"
                    >{{ errors.first('sales area') }}
                </span>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <label class="vs-input--label">Territory</label>
                <v-select v-validate="'required'" name="territory" v-model="dataGet.territory" label="name" placeholder="Select territory" :options="territory" @input="setSelectedTerritory">
                    <template slot="option" slot-scope="option">
                        <div style="align-items: center;"><span>{{option.code}} {{option.name}}</span></div>
                    </template>
                </v-select>
                <span
                    class="text-danger text-sm"
                    v-show="errors.has('territory')"
                    >{{ errors.first('territory') }}
                </span>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <label class="vs-input--label">Distribution Channel</label>
                <v-select v-validate="'required'" name="customer category" v-model="dataGet.customer_category" label="name" placeholder="Select customer category" :options="customerCategory" @input="setSelectedCustomerCategory">
                    <template slot="option" slot-scope="option">
                        <div style="align-items: center;"><span>{{option.name}}</span></div>
                    </template>
                </v-select>
                <span
                    class="text-danger text-sm"
                    v-show="errors.has('customer category')"
                    >{{ errors.first('customer category') }}
                </span>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <label class="vs-input--label">Group Customer</label>
                <v-select v-validate="'required'" name="group customer" v-model="dataGet.group_customer" label="name" placeholder="Select group customer" :options="groupCustomer" @input="setSelectedGroupCustomer">
                    <template slot="option" slot-scope="option">
                        <div style="align-items: center;"><span>{{option.name}}</span></div>
                    </template>
                </v-select>
                <span
                    class="text-danger text-sm"
                    v-show="errors.has('group customer')"
                    >{{ errors.first('group customer') }}
                </span>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <label class="vs-input--label">Customer</label>
                <v-select name="customer" v-validate="'required'" multiple label="name" v-model="dataGet.customer" :options="paginated" @search="query => search = query" placeholder="Select customer" @input="setSelectedCustomer" :filterable="false">
                    <template slot="option" slot-scope="option">
                        <div style="align-items: center;"><span>{{option.code}} {{option.name}}</span></div>
                    </template>
                    <li slot="list-footer" class="pagination">
                        <button @click="offset -= 10" :disabled="!hasPrevPage">Prev</button>
                        <button @click="offset += 10" :disabled="!hasNextPage">Next</button>
                    </li>
                </v-select>

                <span
                    class="text-danger text-sm"
                    v-show="errors.has('customer')"
                    >{{ errors.first('customer') }}
                </span>
            </div>
        </div>
        
        <div class="vx-row mb-6">
            <div class="vx-col w-full" style="margin-bottom: 150px">
                 <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
            </div>
        </div>
    </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";

export default {
    components: {
        Datepicker,
        vSelect
    },
    props: {
        ruleId: {
            type: Number
        },
        master: {
            type: String
        }
    },
    created() {
        var dateObj = new Date();
        var month = dateObj.getUTCMonth() + 1; //months from 1-12
        var day = dateObj.getUTCDate();
        var year = dateObj.getUTCFullYear();

        this.date_now = year + "-" + month + "-" + day;
        if(!(this.ruleId)){
            this.getCode();
            this.getPrice()
            this.getDiscount()
            this.getSalesChannel()
            this.getCustomerCategory()
            this.getSalesArea()
            this.getGroupCustomer()
        }
    },
    data() {
        return this.initialState();
    },
    methods: {
        initialState() {
            return {
                date_now: '',
                prices: [],
                discounts: [],
                salesChannel: [],
                customerCategory: [],
                salesArea: [],
                territory: [],
                groupCustomer: [],
                customer: [],
                search: '',
                offset: 0,
                limit: 10,
                dataGet: {
                    sales_channel: [],
                    customer_category: [],
                    sales_area: [],
                    territory: [],
                    group_customer: [],
                    customer: []
                },
                // [0]: list price, [1]: discount
                date_from: [0, 0],
                date_to: [0, 0],
                limit_date_from: '',
                limit_date_to: '',
                data: {
                    code: "",
                    priority: "",
                    name: "",
                    price_id: "",
                    price_code: "",
                    price_name: "",
                    discount_id: "",
                    discount_code: "",
                    discount_name: "",
                    valid_from: "",
                    valid_to: "",
                    is_active: false,
                    is_master: false,
                    sales_channel: [],
                    customer_category: [],
                    sales_area: [],
                    territory: [],
                    group_customer: [],
                    customer: []
                },
                disabledDatesFrom: {
                    to: new Date(), // Disable all dates up to specific date
                    from: new Date(), // Disable all dates after specific date
                },
                disabledDatesTo: {
                    to: new Date(), // Disable all dates up to specific date
                    from: new Date(), // Disable all dates after specific date
                }
            };
        },
        setSelectedPrice(value) {
            console.log("set selected price")
            this.data.price_id = value.id;
            this.data.price_code = value.code;
            this.data.price_name = value.name;
            this.data.valid_from = '';
            this.data.valid_to = '';

            let from = new Date(value.valid_from);
            from.setDate(from.getDate());
            let to = new Date(value.valid_to);
            to.setDate(to.getDate() + 1);
            this.date_from[0] = from;
            this.date_to[0] = to;

            if(this.date_from[0] !== 0 && this.date_from[1] !== 0 && this.date_to[0] !== 0 && this.date_to[1] !== 0){
                // (this.date_from[0] < this.date_from[1]) ? (this.limit_date_from = this.date_from[1]) : (this.limit_date_from = this.date_from[0]);
                // (this.date_to[0] < this.date_to[1]) ? (this.limit_date_to = this.date_to[1]) : (this.limit_date_to = this.date_to[0]);
                (this.date_from[0] < this.date_from[1]) ? (this.disabledDatesFrom.to = this.date_from[1], this.disabledDatesTo.to = this.date_from[1]) : (this.disabledDatesFrom.to = this.date_from[0], this.disabledDatesTo.to = this.date_from[0]);
                (this.date_to[0] < this.date_to[1]) ? (this.disabledDatesFrom.from = this.date_to[1], this.disabledDatesTo.from = this.date_to[1]) : (this.disabledDatesFrom.from = this.date_to[0], this.disabledDatesTo.from = this.date_to[0]);
            }
        },
        setSelectedDiscount(value) {
            console.log("set selected discount")
            this.data.discount_id = value.id;
            this.data.discount_code = value.code;
            this.data.discount_name = value.name;
            this.data.valid_from = '';
            this.data.valid_to = '';

            let from = new Date(value.valid_from);
            from.setDate(from.getDate() - 1);
            let to = new Date(value.valid_to);
            to.setDate(to.getDate() + 1);
            this.date_from[1] = from;
            this.date_to[1] = to;

            if(this.date_from[0] !== 0 && this.date_from[1] !== 0 && this.date_to[0] !== 0 && this.date_to[1] !== 0){
                // (this.date_from[0] < this.date_from[1]) ? (this.limit_date_from = this.date_from[1]) : (this.limit_date_from = this.date_from[0]);
                // (this.date_to[0] < this.date_to[1]) ? (this.limit_date_to = this.date_to[1]) : (this.limit_date_to = this.date_to[0]);
                (this.date_from[0] < this.date_from[1]) ? (this.disabledDatesFrom.to = this.date_from[1], this.disabledDatesTo.to = this.date_from[1]) : (this.disabledDatesFrom.to = this.date_from[0], this.disabledDatesTo.to = this.date_from[0]);
                (this.date_to[0] < this.date_to[1]) ? (this.disabledDatesFrom.from = this.date_to[1], this.disabledDatesTo.from = this.date_to[1]) : (this.disabledDatesFrom.from = this.date_to[0], this.disabledDatesTo.from = this.date_to[0]);
            }
        },
        setSelectedCustomerCategory(value) {
            this.dataGet.customer = []
            var x = []
            var _this = this
            // value.forEach(function(element){
                if(value == null){
                    x = []
                } else if(value.name != "All"){
                    if(!(x.includes(value.id))){
                        x.push(value.id)
                    }
                } else {
                    _this.customerCategory.forEach(function(element){
                        if(element.id && !(x.includes(element.id))){
                           x.push(element.id) 
                        }
                    })
                }
            // })
            console.log(x)

            if(x.length != 0){
                this.data.customer_category = x
                if(this.data.group_customer.length != 0 && this.data.territory.length != 0){
                    this.getCustomer()
                }
            } else {
                this.customer = []
                this.data.customer_category = []
            }
        },
        setSelectedSalesChannel(value) {
            var x = []
            var _this = this
            if(value == null){
                x = []
            } else if(value.name != "All"){
                if(!(x.includes(value.id))){
                    x.push(value.id)
                }
            } else {
                _this.salesChannel.forEach(function(element){
                    if(element.id && !(x.includes(element.id))){
                        x.push(element.id) 
                    }
                })
            }
            console.log(x)

            if(x.length != 0){
                this.data.sales_channel = x
            } else {
                this.data.sales_channel = []
            }
        },
        setSelectedSalesArea(value) {
            var x = []
            var _this = this
            if(value == null){
                x = []
            } else if(value.name != "All"){
                if(!(x.includes(value.id))){
                    x.push(value.id)
                }
            } else {
                _this.salesArea.forEach(function(element){
                    if(element.id && !(x.includes(element.id))){
                        x.push(element.id) 
                    }
                })
            }
            console.log(x)

            if(x.length != 0){
                this.data.sales_area = x
                this.getTerritory()
            } else {
                this.territory = []
                this.data.sales_area = []
            }
        },
        setSelectedTerritory(value) {
            var x = []
            var _this = this
            if(value == null){
                x = []
            } else if(value.name != "All"){
                if(!(x.includes(value.ID))){
                    x.push(value.ID)
                }
            } else {
                _this.territory.forEach(function(element){
                    if(element.ID && !(x.includes(element.ID))){
                        x.push(element.ID) 
                    }
                })
            }
            console.log(x)

            if(x.length != 0){
                this.data.territory = x
                if(this.data.customer_category.length != 0 && this.data.group_customer.length != 0){
                    this.getCustomer()
                }
            } else {
                this.customer = []
                this.data.territory = []
            }
        },
        setSelectedGroupCustomer(value) {
            this.dataGet.customer = []
            var x = []
            var _this = this
            if(value == null){
                x = []
            } else if(value.name != "All"){
                if(!(x.includes(value.ID))){
                    x.push(value.ID)
                }
            } else {
                _this.groupCustomer.forEach(function(element){
                    if(element.ID && !(x.includes(element.ID))){
                        x.push(element.ID) 
                    }
                })
            }
            console.log(x)

            if(x.length != 0){
                this.data.group_customer = x
                if(this.data.customer_category.length != 0 && this.data.territory.length != 0){
                    this.getCustomer()
                }
            } else {
                this.customer = []
                this.data.group_customer = []
            }
        },
        setSelectedCustomer(value) {
            var x = []
            var _this = this
            value.forEach(function(element){
                if(element.name != "All"){
                    if(!(x.includes(element.ID))){
                        x.push(element.ID)
                    }
                } else {
                    _this.dataGet.customer = []
                    _this.dataGet.customer = [{name: "All"}]
                    _this.customer.forEach(function(element){
                        if(element.ID && !(x.includes(element.ID))){
                           x.push(element.ID)
                        }
                    })
                }
            })

            if(x.length != 0){
                this.data.customer = x
            } else {
                this.data.customer = []
            }
        },
        getPrice(){
            this.$http
            .get("api/v1/master/prices", {
                params: {
                    search_date: true,
                    search: this.date_now //valid to
                }
            }).then(resp => {
                if (resp.status == "success") {
                    this.prices = resp.data.records;
                }
            });
        },
        getDiscount(){
            this.$http
            .get("api/v1/master/discounts", {
                params: {
                    search_date: true,
                    search: this.date_now //valid to
                }
            }).then(resp => {
                if (resp.status == "success") {
                    this.discounts = resp.data.records;
                }
            });
        },
        getSalesChannel(){
            this.$http
            .get("api/v1/master/sales-channel").then(resp => {
                var _this = this
                if (resp.status == "success") {
                    _this.salesChannel = []
                    if(resp.data.records.length > 0){
                        _this.salesChannel.push({name: "All"})
                    }
                    resp.data.records.forEach(function(element, index){
                        _this.salesChannel.push(resp.data.records[index])
                    })

                    if(this.dataGet.sales_channel.length > 1){
                        this.dataGet.sales_channel = [{name: "All"}]
                    }
                }
            });
        },
        getCustomerCategory(){
            this.$http
            .get("api/v1/master/customer-category").then(resp => {
                var _this = this
                if (resp.status == "success") {
                    _this.customerCategory = []
                    if(resp.data.records.length > 0){
                        _this.customerCategory.push({name: "All"})
                    }
                    resp.data.records.forEach(function(element, index){
                        _this.customerCategory.push(resp.data.records[index])
                    })

                    if(this.dataGet.customer_category.length > 1){
                        this.dataGet.customer_category = [{name: "All"}]
                    }
                }
            });
        },
        getSalesArea(){
            this.$http
            .get("api/v1/master/territory-area", { params: { type: "Sales" } } )
            .then(resp => {

                var _this = this
                if (resp.status == "success") {
                    _this.salesArea = []
                    if(resp.data.records.length > 0){
                        _this.salesArea.push({name: "All"})
                    }
                    resp.data.records.forEach(function(element, index){
                        _this.salesArea.push(resp.data.records[index])
                    })

                    if(this.dataGet.sales_area.length > 1){
                        this.dataGet.sales_area = [{name: "All"}]
                    }
                }
            });
        },
        getGroupCustomer(){
            this.$http
            .get("api/v1/master/customer-group")
            .then(resp => {
                var _this = this
                if (resp.status == "success") {
                    _this.groupCustomer = []
                    if(resp.data.records.length > 0){
                        _this.groupCustomer.push({name: "All"})
                    }
                    resp.data.records.forEach(function(element, index){
                        _this.groupCustomer.push(resp.data.records[index])
                    })

                    if(this.dataGet.group_customer.length > 1){
                        this.dataGet.group_customer = [{name: "All"}]
                    }
                }
            });
        },
        getCustomer(){
            this.$http
            .post("api/v1/master/customer/price-rule", {
                customer_category_id: this.data.customer_category,
                customer_group_id: this.data.group_customer,
                territory_id: this.data.territory
            })
            .then(resp => {
                var _this = this
                if (resp.status == "success") {
                    _this.customer = []
                    if(resp.data.length > 0){
                        _this.customer.push({name: "All"})
                    }
                    resp.data.forEach(function(element, index){
                        _this.customer.push(resp.data[index])
                    })

                    if(this.dataGet.customer.length == this.customer.length-1){
                        _this.dataGet.customer = [{name: "All"}]
                    }
                }
            });
        },
        getTerritory(){
            this.$http
            .post("api/v1/master/territory/price-rule", {territory_area_id: this.data.sales_area})
            .then(resp => {
                var _this = this
                if (resp.status == "success") {
                    _this.territory = []
                    if(resp.data.length > 0){
                        _this.territory.push({name: "All"})
                    }
                    resp.data.forEach(function(element, index){
                        _this.territory.push(resp.data[index])
                    })

                    if(this.dataGet.territory.length == this.territory.length-1){
                        _this.dataGet.territory = [{name: "All"}]
                    }
                }
            });
        },
        handleSubmit() {
            if(this.data.is_master == true && this.data.code != this.master && this.data.is_active == true & this.master != null){
                this.$vs.dialog({
                    type: "confirm",
                    color: "warning",
                    title: `Confirm`,
                    text: this.master + " has been set as master, do you want to continue?",
                    accept: this.doSubmit
                });
            } else if (this.data.is_master == true && this.data.code != this.master && this.data.is_active == false){
                this.$vs.dialog({
                    type: "alert",
                    color: "danger",
                    title: `Information`,
                    text: "Master price rule must be activated.",
                });
            } else if (this.data.is_master == false && this.data.code == this.master){
                this.$vs.dialog({
                    type: "alert",
                    color: "danger",
                    title: `Information`,
                    text: "There is no one master price rule, please set this rule to master.",
                });
            } else if (this.data.is_master == false && this.master == null){
                this.$vs.dialog({
                    type: "alert",
                    color: "danger",
                    title: `Information`,
                    text: "There is no one master price rule, please set this rule to master.",
                });
            } else if (this.data.code == this.master && this.data.is_active == false){
                this.$vs.dialog({
                    type: "alert",
                    color: "danger",
                    title: `Information`,
                    text: "This price rule is set to master, please ativated this price rule.",
                });
            } else {
                this.doSubmit()
            }
            
        },
        doSubmit() {
            this.$validator.validateAll().then(result => {
                if (result) {
                    this.$vs.loading();
                    if (this.ruleId) {
                        console.log(this.paramData())
                        this.putData();
                    } else {
                        this.postData();
                        console.log(this.paramData())
                    }
                }
            });
        },
        paramData() {
            return {
                code: this.data.code,
                priority: this.data.priority,
                name: this.data.name,
                price_id: this.data.price_id,
                price_code: this.data.price_code,
                price_name: this.data.price_name,
                discount_id: this.data.discount_id,
                discount_code: this.data.discount_code,
                discount_name: this.data.discount_name,
                valid_from: this.data.valid_from,
                valid_to: this.data.valid_to,
                is_active: this.data.is_active,
                is_master: this.data.is_master,
                sales_channel: this.data.sales_channel,
                customer_category: this.data.customer_category,
                sales_area: this.data.sales_area,
                territory: this.data.territory,
                group_customer: this.data.group_customer,
                customer: this.data.customer
            };
        },
        postData() {
            this.$http
                .post("/api/v1/master/price-rule", this.paramData())
                .then(resp => {
                this.$vs.loading.close();
                if (resp.code == 200) {
                    this.handleClose();
                    this.$vs.notify({
                        color: "success",
                        title: "Success",
                        text: "New Price Rule Created",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                }
            })
            .catch(error => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
        getCode() {
            this.$vs.loading();
            if (!this.ruleId) {
                this.$http
                .get("/api/v1/setting/master-number-code/PR")
                .then((resp) => {
                if (resp.status == "success") {
                    this.data.code = resp.data;
                    this.$vs.loading.close();
                } else {
                    this.$vs.loading.close();
                }
                });
                console.log(this.data.code)
                if (this.data.code == ""){
                    this.$http
                    .get("/api/v1/master/price-rule/code")
                    .then(resp => {
                        if (resp.code == 200) {
                            this.data.code = resp.data
                        } else {
                            this.$vs.notify({
                                color: "danger",
                                title: "Error",
                                text: resp.message,
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-x-circle"
                            });
                        }
                    })
                    .catch(error => {
                        this.$vs.loading.close();
                        console.log(error);
                    });
                }
            }
        },
        putData() {
        this.$http
            .put("/api/v1/master/price-rule/" + this.ruleId, this.paramData())
            .then(resp => {
            this.$vs.loading.close();
            if (resp.code == 200) {
                this.handleClose();
                this.$vs.notify({
                    color: "success",
                    title: "Success",
                    text: "Price Rule Updated",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                });
            } else {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: resp.message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                });
            }
            })
            .catch(error => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
        handleClose() {
            window.scrollTo(0, 0);
            this.$emit("close");
        },
        getData() {
        this.$vs.loading();
            if (this.ruleId) {
                this.$http
                .get("/api/v1/master/price-rule/" + this.ruleId)
                .then(resp => {
                    this.$vs.loading.close();
                    var _this = this
                    if (resp.code == 200) {
                        this.data.code = resp.data.code;
                        this.data.priority = resp.data.priority;
                        this.data.name = resp.data.name;
                        this.data.price_id = resp.data.price_id;
                        this.data.price_code = resp.data.price_code;
                        this.data.price_name = resp.data.price_name;
                        this.data.discount_id = resp.data.discount_id;
                        this.data.discount_code = resp.data.discount_code;
                        this.data.discount_name = resp.data.discount_name;
                        this.data.valid_from = resp.data.valid_from;
                        this.data.valid_to = resp.data.valid_to;
                        this.data.is_master = resp.data.is_master;
                        this.data.is_active = resp.data.is_active;

                        this.date_from = [new Date(resp.data.discount_valid_from), new Date(resp.data.price_valid_from)]
                        this.date_to = [new Date(resp.data.discount_valid_to), new Date(resp.data.price_valid_to)]

                        if(this.date_from[0] !== 0 && this.date_from[1] !== 0 && this.date_to[0] !== 0 && this.date_to[1] !== 0){
                            (this.date_from[0] < this.date_from[1]) ? (this.disabledDatesFrom.to = this.date_from[1], this.disabledDatesTo.to = this.date_from[1]) : (this.disabledDatesFrom.to = this.date_from[0], this.disabledDatesTo.to = this.date_from[0]);
                            (this.date_to[0] < this.date_to[1]) ? (this.disabledDatesFrom.from = this.date_to[1], this.disabledDatesTo.from = this.date_to[1]) : (this.disabledDatesFrom.from = this.date_to[0], this.disabledDatesTo.from = this.date_to[0]);
                        }

                        resp.data.customer_category.forEach(function(element){
                            _this.dataGet.customer_category.push({id: element.customer_category_id, name: element.customer_category_name})
                            _this.data.customer_category.push(element.customer_category_id)
                        })

                        // console.log(this.data.customer_category)

                        resp.data.customer_group.forEach(function(element){
                            _this.dataGet.group_customer.push({id: element.customer_group_id, name: element.customer_group_name})
                            _this.data.group_customer.push(element.customer_group_id)
                        })

                        // console.log(this.data.group_customer)

                        resp.data.customer.forEach(function(element){
                            _this.dataGet.customer.push({id: element.customer_id, name: element.customer_name})
                            _this.data.customer.push(element.customer_id)
                        })

                        // console.log(this.data.customer)

                        resp.data.sales_area.forEach(function(element){
                            _this.dataGet.sales_area.push({id: element.sales_area_id, name: element.sales_area_name})
                            _this.data.sales_area.push(element.sales_area_id)
                        })

                        // console.log(this.data.sales_area)

                        resp.data.sales_channel.forEach(function(element){
                            _this.dataGet.sales_channel.push({id: element.sales_channel_id, name: element.sales_channel_name})
                            _this.data.sales_channel.push(element.sales_channel_id)
                        })

                        // console.log(this.data.sales_channel)

                        resp.data.territory.forEach(function(element){
                            _this.dataGet.territory.push({id: element.territory_id, name: element.territory_name})
                            _this.data.territory.push(element.territory_id)
                        })
                        
                        this.getTerritory()
                        this.getSalesChannel()
                        this.getSalesArea()
                        this.getCustomerCategory()
                        this.getGroupCustomer() 
                        this.getDiscount() 
                        this.getPrice() 
                        this.getCustomer()
                        
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle"
                        });
                    }
                })
                .catch(error => {
                    this.$vs.loading.close();
                    console.log(error);
                });
            } else {
                this.getSalesChannel()
                this.getSalesArea()
                this.getCustomerCategory()
                this.getGroupCustomer() 
                this.getDiscount() 
                this.getPrice() 
                this.getCustomer()
                this.getTerritory()
            }
        },
        
    },
    mounted() {
        if (this.ruleId) {
            this.getData();
        }
    },
    computed: {
        filtered () {
            return this.customer.filter(customer => customer.name.toLowerCase().includes(this.search.toLowerCase()));
        },
        paginated () {
            return this.filtered.slice(this.offset, this.limit + this.offset);
        },
        hasNextPage () {
            const nextOffset = this.offset + 10;
            return Boolean(this.filtered.slice(nextOffset, this.limit + nextOffset).length);
        },
        hasPrevPage () {
            const prevOffset = this.offset - 10;
            return Boolean(this.filtered.slice(prevOffset, this.limit + prevOffset).length);
        }
    },
    watch: {
        ruleId() {
            Object.assign(this.$data, this.initialState());
            this.getData();
            this.getCode();
        },
        "search": function() {
            this.offset = 0
        },
        "data.valid_from": function() {
            if (!this.ruleId) {
                console.log("watch valid from")
                this.data.valid_to = ''
            }
            
            this.disabledDatesTo.to = new Date(this.data.valid_from);
        }
    }
};
</script>
<style scoped>
  .pagination {
    display: flex;
    margin: .25rem .25rem 0;
  }
  .pagination button {
    flex-grow: 1;
  }
  .pagination button:hover {
    cursor: pointer;
  }
</style>