<template>
  <vx-card title="Pricing Management">
    <vs-tabs>
      <vs-tab label="List Price">
        <list-price></list-price>
      </vs-tab>
      <vs-tab label="Discount">
        <div class="tab-text">
          <discount></discount>
        </div>
      </vs-tab>
      <vs-tab label="Pricing Rule">
        <div class="tab-text">
          <list-price-rule></list-price-rule>
        </div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import ListPrice from "./ListPrice";
import Discount from "./Discount";
import ListPriceRule from "./ListPriceRule";
export default {
  components: {
    ListPrice,
    Discount,
    ListPriceRule
  }
};
</script>