<template>
  <div class="tab-text">
    <vs-button
      class="mt-2"
      color="primary"
      type="border"
      icon-pack="feather"
      icon="icon-plus"
      @click="handleCreate()"
    >Create</vs-button>
    <div class="vx-row mb-12">
      <div v-bind:class="[detail ? detailShow : '', detailHide]">
        <vs-table
          search
          stripe
          border
          description
          :sst="true"
          :data="table.data"
          :max-items="table.length"
          :total="table.total"
          @search="handleSearch"
          @change-page="handleChangePage"
          @sort="handleSort"
        >
          <template slot="header">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2">{{this.table.start}} - {{this.table.end}} of {{this.table.total}}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item
                  v-for="item in table.limits"
                  :key="item"
                  @click="handleChangelength(item)"
                >
                  <span>{{item}}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </template>
          <template slot="thead">
            <vs-th width="10%"></vs-th>
            <vs-th sort-key="code">Price Rule</vs-th>
            <!-- <vs-th width="7%" sort-key="name">Priority</vs-th> -->
            <!-- <vs-th sort-key="model">Name</vs-th> -->
            <vs-th sort-key="price_id">Price</vs-th>
            <vs-th sort-key="discount_id">Discount</vs-th>
            <vs-th sort-key="valid_from">Valid</vs-th>
            <vs-th>Applicable To</vs-th>
          </template>

          <template slot-scope="{data}">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :class="data[indextr].class">
                <vs-td class="whitespace-no-wrap">
                    <feather-icon
                    title="Edit"
                    icon="EditIcon"
                    svgClasses="w-5 h-5 hover:text-primary stroke-current"
                    class="ml-2"
                    @click.stop="handleEdit(data[indextr].id)"
                    />
                    <feather-icon
                    title="Delete"
                    icon="TrashIcon"
                    svgClasses="w-5 h-5 hover:text-danger stroke-current"
                    class="ml-2"
                    @click.stop="handleDelete(data[indextr].id, data[indextr].code)"
                    />
                </vs-td>
                <vs-td :data="data[indextr].code">
                    <div class="vx-row ml-0">
                        <span class="w-5/5">{{ data[indextr].code }}</span>
                    </div>
                    <div class="vx-row ml-0">
                        <span class="w-5/5">{{ data[indextr].name }}</span>
                    </div>
                    <div class="vx-row ml-0">
                        <span class="w-5/5">Priority : {{ data[indextr].priority }}</span>
                    </div>
                    <div class="vx-row ml-0">
                        <span v-if="data[indextr].is_master == true" class="w-5/5 mr-2"><strong>Master</strong></span>
                        <span v-else class="w-5/5"></span>
                        <span v-if="data[indextr].is_active == true" style="background-color: #44fb44; color: white" class="w-5/5 pl-1 pr-1">Active</span>
                        <span v-else style="background-color: red; color: white" class="w-5/5 pl-1 pr-1">Inactive</span>
                    </div>
                </vs-td>
                <vs-td :data="data[indextr].price_code">
                    <div class="vx-row ml-0">
                        <span class="w-5/5">{{ data[indextr].price_code }}</span>
                    </div>
                    <div class="vx-row ml-0">
                        <span class="w-5/5">{{ data[indextr].price_name }}</span>
                    </div>

                </vs-td>
                <vs-td :data="data[indextr].discount_code">
                    <div class="vx-row ml-0">
                        <span class="w-5/5">{{ data[indextr].discount_code }}</span>
                    </div>
                    <div class="vx-row ml-0">
                        <span class="w-5/5">{{ data[indextr].discount_name }}</span>
                    </div>
                </vs-td>
                <vs-td :data="data[indextr].valid_from">
                    <div class="vx-row ml-0">
                        <span>From : {{ data[indextr].valid_from }}</span>
                    </div>
                    <div class="vx-row ml-0">
                        <span>To : {{ data[indextr].valid_to }}</span>
                    </div>
                </vs-td>
                <vs-td>
                    <div class="vx-row ml-0">
                        <span v-if="data[indextr].sales_channel.length>1">Sales Channel : <b>All</b></span>
                        <span v-else>Sales Channel : <b>{{data[indextr].sales_channel[0].sales_channel_name}}</b></span>
                    </div>
                    <div class="vx-row ml-0">
                        <span v-if="data[indextr].sales_area.length>1">Sales Area : <b>All</b></span>
                        <span v-else>Sales Area : <b>{{ data[indextr].sales_area[0].sales_area_name }}</b></span>
                    </div>
                    <div class="vx-row ml-0">
                        <span v-if="data[indextr].territory.length>1">Territory : <b>All</b></span>
                        <span v-else>Territory : <b>{{ data[indextr].territory[0].territory_name }}</b></span>
                    </div>
                    <div class="vx-row ml-0">
                        <span v-if="data[indextr].customer_category.length>1">Distribution Channel : <b>All</b></span>
                        <span v-else>Distribution Channel : <b>{{ data[indextr].customer_category[0].customer_category_name }}</b></span>
                    </div>
                    <div class="vx-row ml-0">
                        <span v-if="data[indextr].customer_group.length>1">Group Customer : <b>All</b></span>
                        <span v-else >Group Customer : <b>{{ data[indextr].customer_group[0].customer_group_name }}</b></span>
                    </div>
                    <div class="vx-row ml-0">
                        <span v-if="dataCustomerCount[indextr]==data[indextr].custCount">Customer : <b>All</b></span>
                        <span v-else-if="dataCustomerCount[indextr]>1" >Customer : <b>{{ data[indextr].customer[0].customer_name }}</b>, and {{dataCustomerCount[indextr]-1}} more ...</span>
                        <span v-else>Customer : <b>{{ data[indextr].customer[0].customer_name }}</b></span>
                        <span class="hidden">{{customerCount[indextr]}}</span>
                    </div>
                </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination style="padding-top:5px" :total="table.totalPage" v-model="setPage" />
      </div>
      <!-- form -->
      <transition name="detail-fade">
        <div v-if="detail" v-bind:class="[detail ? detailShow : '', detailHide]">
          <vs-row>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                class="ml-auto"
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              >Close</vs-button>
            </vs-col>
            <vs-col
              class="vertical-divider"
              vs-offset="0"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="1"
            >
              <vs-button
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              ></vs-button>
            </vs-col>
            <!-- form component -->
            <list-price-rule-form
              @close="handleClose"
              :ruleId="ruleId"
              :master="master"
            ></list-price-rule-form>
          </vs-row>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import ListPriceRuleForm from "./ListPriceRuleForm";
export default {
  components: {
    ListPriceRuleForm
  },
  data() {
    return {
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      statusView: false,
      deleteId: null,
      ruleId: null,
      master: null,
      table: {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0
      },
      dataCustomerCount: [],
      customerCount: [],
      customer_category: [],
      group_customer: [],
      territory: []
    };
  },
  methods: {
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    handleClose() {
      this.getData();
      this.detail = false;
    },
    getCustomer(index){
      this.$http
      .post("api/v1/master/customer/price-rule", {
        customer_category_id: this.customer_category[index],
        customer_group_id: this.group_customer[index],
        territory_id: this.territory[index]
      })
      .then(resp => {
        if (resp.status == "success") {
          this.customerCount.push(resp.data.length)
          this.table.data[index]["custCount"] = resp.data.length
        }
      });
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/price-rule", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
          }
        })
        .then(resp => {
          if (resp.status == "success") {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;
            var _this = this
            this.dataCustomerCount = []
            this.customerCount = []
            this.table.data.forEach(function(element, index){
              if(element.is_master == true){
                _this.master = element.code
                _this.table.data[index]["class"] = "master"
              }
              _this.dataCustomerCount.push(element.customer.length)

              var a = []
              element.customer_category.forEach(function(element1){
                a.push(element1.customer_category_id)
              })
              _this.customer_category.push(a)

              var b = []
              element.customer_group.forEach(function(element2){
                b.push(element2.customer_group_id)
              })
              _this.group_customer.push(b)

              var c = []
              element.territory.forEach(function(element3){
                c.push(element3.territory_id)
              })
              _this.territory.push(c)    
            })

            for(var x = 0; x < this.dataCustomerCount.length; x++){
              this.getCustomer(x)
              
            }
            this.setStartEnd();
            this.$vs.loading.close();
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleDelete(id, code) {
      this.detail = false;
      this.deleteId = id;
      if(code == this.master){
        this.$vs.dialog({
          type: "alert",
          color: "danger",
          title: `Information`,
          text: "You cannot delete this master price rule.",
        });
      } else {
        this.$vs.dialog({
          type: "confirm",
          color: "danger",
          title: `Confirm`,
          text: "Please confirm to delete this list price rule data",
          accept: this.acceptDelete
        });
      }
    },
    acceptDelete() {
      this.$vs.loading();
      this.$http
        .delete("/api/v1/master/price-rule/" + this.deleteId)
        .then(resp => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "The price rule was successfully deleted",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
          this.getData();
        })
        .catch(error => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleCreate() {
        this.statusView = false;
        this.ruleId = null;
        this.detail = true;
    },
    handleEdit(id) {
        this.statusView = false;
        this.ruleId = id;
        this.detail = true;
    },
  },
  mounted() {
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      }
    }
  },
  watch: {
  }
};
</script>

 <style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
.master > td {
  background-color: #d0cdf0;
}
</style>